// System
import Add from './system/Add.vue';
import AddFilled from './system/AddFilled.vue';
import Arrow from './system/Arrow.vue';
import ArrowCircle from './system/ArrowCircle.vue';
import Cancel from './system/Cancel.vue';
import ClipboardCopy from './system/ClipboardCopy.vue';
import Caret from './system/Caret.vue';
import CaretDown from './system/CaretDown.vue';
import CheckBox from './system/CheckBox.vue';
import CheckBoxEmpty from './system/CheckBoxEmpty.vue';
import Delete from './system/Delete.vue';
import DeleteFilled from './system/DeleteFilled.vue';
import Edit from './system/Edit.vue';
import Menu from './system/Menu.vue';
import NavigationDrawer from './system/NavigationDrawer.vue';
import Search from './system/Search.vue';
import Warning from './system/Warning.vue';
import Download from './system/Download.vue';
import Plus from './system/Plus.vue';
import ChevronDown from './system/ChevronDown.vue';

// Product
import Amex from './product/Amex.vue';
import ApplePay from './product/ApplePay.vue';
import Avatar from './product/Avatar.vue';
import Bank from './product/Bank.vue';
import BankingBill from './product/BankingBill.vue';
import Booking from './product/Booking.vue';
import BookOpen from './product/BookOpen.vue';
import Business from './product/Business.vue';
import BusinessAccount from './product/BusinessAccount.vue';
import BusinessScale from './product/BusinessScale.vue';
import Calendar from './product/Calendar.vue';
import CalendarClock from './product/CalendarClock.vue';
import Car from './product/Car.vue';
import Cash from './product/Cash.vue';
import Chart from './product/Chart.vue';
import Clock from './product/Clock.vue';
import Corporation from './product/Corporation.vue';
import Coupon from './product/Coupon.vue';
import CreditCard from './product/CreditCard.vue';
import Department from './product/Department.vue';
import Destination from './product/Destination.vue';
import DinersClub from './product/DinersClub.vue';
import Discover from './product/Discover.vue';
import Email from './product/Email.vue';
import Employee from './product/Employee.vue';
import Fleet from './product/Fleet.vue';
import FileStar from './product/FileStar.vue';
import Jcb from './product/Jcb.vue';
import MasterCard from './product/MasterCard.vue';
import Name from './product/Name.vue';
import Notes from './product/Notes.vue';
import Onboarding from './product/Onboarding.vue';
import PaymentSuccess from './product/PaymentSuccess.vue';
import PaymentFailed from './product/PaymentFailed.vue';
import PersonAdd from './product/PersonAdd.vue';
import Pickup from './product/Pickup.vue';
import Refund from './product/Refund.vue';
import TravelRestriction from './product/TravelRestriction.vue';
import UnionPay from './product/UnionPay.vue';
import User from './product/User.vue';
import Visa from './product/Visa.vue';
import Worker from './product/Worker.vue';

// Phoenix
import PhoenixAuditLog from './phoenix/AuditLog.vue';
import PhoenixBooking from './phoenix/Booking.vue';
import PhoenixBookingSidebar from './phoenix/BookingSidebar.vue';
import PhoenixDriver from './phoenix/Driver.vue';
import PhoenixDriverHistoryLocation from './phoenix/DriverHistoryLocation.vue';
import PhoenixLocation from './phoenix/Location.vue';
import PhoenixLocationHistory from './phoenix/LocationHistory.vue';
import PhoenixLocationLive from './phoenix/LocationLive.vue';
import PhoenixFleet from './phoenix/Fleet.vue';
import PhoenixPassenger from './phoenix/Passenger.vue';
import PhoenixProductSelector from './phoenix/ProductSelector.vue';
import PhoenixTariff from './phoenix/Tariff.vue';
import PhoenixTrip from './phoenix/Trip.vue';
import PhoenixUser from './phoenix/User.vue';
import PhoenixSingleUser from './phoenix/SingleUser.vue';
import PhoenixExit from './phoenix/Exit.vue';
import PhoenixActionEditPencil from './phoenix/actions/EditPencil.vue';
import PhoenixStepTypePickup from './phoenix/step-types/Pickup.vue';
import PhoenixStepTypeDropoff from './phoenix/step-types/Dropoff.vue';

export default {
  system: {
    Add,
    AddFilled,
    Arrow,
    ArrowCircle,
    Cancel,
    Caret,
    CaretDown,
    CheckBox,
    CheckBoxEmpty,
    ClipboardCopy,
    Delete,
    DeleteFilled,
    Edit,
    Menu,
    NavigationDrawer,
    Search,
    Warning,
    Plus,
    Download,
    ChevronDown,
  },
  product: {
    Amex,
    ApplePay,
    Avatar,
    Bank,
    BankingBill,
    Booking,
    BookOpen,
    Business,
    BusinessAccount,
    BusinessScale,
    Calendar,
    CalendarClock,
    Car,
    Cash,
    Chart,
    Clock,
    Corporation,
    Coupon,
    CreditCard,
    Department,
    Destination,
    DinersClub,
    Discover,
    Email,
    Employee,
    Fleet,
    FileStar,
    Jcb,
    MasterCard,
    Name,
    Notes,
    Onboarding,
    PaymentSuccess,
    PaymentFailed,
    Pickup,
    PersonAdd,
    Refund,
    TravelRestriction,
    UnionPay,
    User,
    Visa,
    Worker,
  },
  phoenix: {
    AuditLog: PhoenixAuditLog,
    Booking: PhoenixBooking,
    BookingSidebar: PhoenixBookingSidebar,
    Driver: PhoenixDriver,
    DriverHistoryLocation: PhoenixDriverHistoryLocation,
    Exit: PhoenixExit,
    Fleet: PhoenixFleet,
    Location: PhoenixLocation,
    LocationHistory: PhoenixLocationHistory,
    LocationLive: PhoenixLocationLive,
    Passenger: PhoenixPassenger,
    ProductSelector: PhoenixProductSelector,
    Tariff: PhoenixTariff,
    Trip: PhoenixTrip,
    SingleUser: PhoenixSingleUser,
    User: PhoenixUser,
    ActionEditPencil: PhoenixActionEditPencil,
    StepTypePickup: PhoenixStepTypePickup,
    StepTypeDropoff: PhoenixStepTypeDropoff,
  },
  allIcons() {
    return {
      icons_phoenix_audit_log: { component: PhoenixAuditLog },
      icons_phoenix_booking: { component: PhoenixBooking },
      icons_phoenix_booking_sidebar: { component: PhoenixBookingSidebar },
      icons_phoenix_driver_history_location: { component: PhoenixDriverHistoryLocation },
      icons_phoenix_driver: { component: PhoenixDriver },
      icons_phoenix_exit: { component: PhoenixExit },
      icons_phoenix_fleet: { component: PhoenixFleet },
      icons_phoenix_location: { component: PhoenixLocation },
      icons_phoenix_location_history: { component: PhoenixLocationHistory },
      icons_phoenix_location_live: { component: PhoenixLocationLive },
      icons_phoenix_passenger: { component: PhoenixPassenger },
      icons_phoenix_product_selector: { component: PhoenixProductSelector },
      icons_phoenix_tariff: { component: PhoenixTariff },
      icons_phoenix_trip: { component: PhoenixTrip },
      icons_phoenix_single_user: { component: PhoenixSingleUser },
      icons_phoenix_user: { component: PhoenixUser },
      icons_phoenix_action_edit_pencil: { component: PhoenixActionEditPencil },
      icons_phoenix_step_type_pickup: { component: PhoenixStepTypePickup },
      icons_phoenix_step_type_dropoff: { component: PhoenixStepTypeDropoff },

      icons_product_amex: { component: Amex },
      icons_product_apple_pay: { component: ApplePay },
      icons_product_avatar: { component: Avatar },
      icons_product_bank: { component: Bank },
      icons_product_banking_bill: { component: BankingBill },
      icons_product_booking: { component: Booking },
      icons_product_business: { component: Business },
      icons_product_business_account: { component: BusinessAccount },
      icons_product_business_scale: { component: BusinessScale },
      icons_product_calendar: { component: Calendar },
      icons_product_car: { component: Car },
      icons_product_cash: { component: Cash },
      icons_product_chart: { component: Chart },
      icons_product_clock: { component: Clock },
      icons_product_corporation: { component: Corporation },
      icons_product_coupon: { component: Coupon },
      icons_product_credit_card: { component: CreditCard },
      icons_product_department: { component: Department },
      icons_product_destination: { component: Destination },
      icons_product_diners_club: { component: DinersClub },
      icons_product_discover: { component: Discover },
      icons_product_email: { component: Email },
      icons_product_employee: { component: Employee },
      icons_product_fleet: { component: Fleet },
      icons_product_jcb: { component: Jcb },
      icons_product_mastercard: { component: MasterCard },
      icons_product_name: { component: Name },
      icons_product_notes: { component: Notes },
      icons_product_onboarding: { component: Onboarding },
      icons_product_payment_success: { component: PaymentSuccess },
      icons_product_payment_failed: { component: PaymentFailed },
      icons_product_pickup: { component: Pickup },
      icons_product_person_add: { component: PersonAdd },
      icons_product_refund: { component: Refund },
      icons_product_travel_restriction: { component: TravelRestriction },
      icons_product_union_pay: { component: UnionPay },
      icons_product_user: { component: User },
      icons_product_visa: { component: Visa },
      icons_product_worker: { component: Worker },
      icons_book_open: { component: BookOpen },
      icons_calendar_clock: { component: CalendarClock },
      icons_file_start: { component: FileStar },

      icons_system_add_filled: { component: AddFilled },
      icons_system_add: { component: Add },
      icons_system_arrow: { component: Arrow },
      icons_system_arrow_circle: { component: ArrowCircle },
      icons_system_cancel: { component: Cancel },
      icons_system_caret_down: { component: CaretDown },
      icons_system_caret: { component: Caret },
      icons_system_checkbox_empty: { component: CheckBoxEmpty },
      icons_system_checkbox: { component: CheckBox },
      icons_system_delete_filled: { component: DeleteFilled },
      icons_system_delete: { component: Delete },
      icons_system_download: { component: Download },
      icons_system_edit: { component: Edit },
      icons_system_menu: { component: Menu },
      icons_system_navigation_drawer: { component: NavigationDrawer },
      icons_system_plus: { component: Plus },
      icons_system_search: { component: Search },
      icons_system_warning: { component: Warning },
      icons_system_clipboard_copy: { component: ClipboardCopy },
      icons_system_chevron_down: { component: ChevronDown },
    };
  },
};
